import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { ReactComponent as CreateAccountRoot } from "../assets/Icons/CreateAccountRoot.svg";
import { ReactComponent as VisibilityOffOutlined } from "../assets/Icons/visibility_24px_outlined.svg";
import { ReactComponent as VisibilityOutlined } from "../assets/Icons/visibility_off_24px_outlined.svg";
import { ReactComponent as Line } from "../assets/Icons/Line.svg";
import AuthFormErrorText from "../components/AuthFormErrorText.js";
import CreateAccountREQ from "../api/Account.js";
import { useNavigate } from "react-router-dom";
import storeSession from "../utilities/session/sessionInitialIzer.js";
import isEmpty from "../utilities/isEmpty";
// import identifyUserWithLou from "../utilities/session/identifyUserWithLou";
import "../styles/pages/CreateAccount.css";
import { appLogo } from "config";
import GoogleAuthButton from "components/auth/GoogleButton";
import { LinkedInButton } from "components/auth/LinkedInButton";
import FormFieldValidationTick from "components/FormFieldValidationTick";
import BtnLoader from "components/Loader/btn-loader";

/**
 *  Signup form containing
 *  - basic form
 *  - google signup button
 *  - linkedIn signup button
 */

const Form = ({ formSubmitted, setFormIsSubmited }) => {
  const defaultFormValidStatus = {
    Firstname: undefined,
    Lastname: undefined,
    Email: undefined,
  };

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formFieldIsValid, setFormFieldIsValid] = useState(
    defaultFormValidStatus
  );
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [formErrorText, setFormErrorText] = useState("");
  const [sessionData, setSessionData] = useState(null);

  const baseUrl = `${window.location.protocol}//${window.location.host}/linkedin`;

  /**
   * Check if name is valid
   *  - name lenght greater than 1 text
   *  - name does not contain number and spaces
   * @param {String}: name
   * @returns {boolean}
   */
  const isValidName = (name) => {
    if (name && name.length > 1) {
      return true;
    }
    return false;
  };

  /**
   * Check if email is valid
   * @param {String}: email
   * @returns {boolean}: true/false
   */
  const isValidEmailFormat = (email) => {
    let format =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email && email.match(format)) {
      return true;
    }
    return false;
  };

  /**
   * Validate field by type
   * @param {String} fieldname
   * @param {String} value
   */
  const validateFormField = (fieldname, value) => {
    switch (fieldname) {
      case "firstname":
        setFormFieldIsValid({
          ...formFieldIsValid,
          Firstname: isValidName(value),
        });
        break;
      case "lastname":
        setFormFieldIsValid({
          ...formFieldIsValid,
          Lastname: isValidName(value),
        });
        break;
      case "email":
        setFormFieldIsValid({
          ...formFieldIsValid,
          Email: isValidEmailFormat(value),
        });
        break;
      default:
        break;
    }
  };

  /**
   * Create user account
   * - send user info to server
   *
   * @param {String} fieldname
   * @param {String} value
   */

  const handleFormSubmit = async (FormFieldIsValid) => {
    const { Firstname, Lastname, Email } = FormFieldIsValid;
    const formIsValidated = Firstname && Lastname && Email;
    if (formIsValidated) {
      setFormSubmitInProgress(true);
      const formData = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: password,
      };

      const data = await CreateAccountREQ.form(formData);
      if (data.status === "success") {
        storeSession(data.data);
        setFormSubmitInProgress(false);
        setFormIsSubmited(true);
      } else {
        if (data.response && data.response.status === 400) {
          if (data.response.data.error === "duplicate email") {
            setFormErrorText(
              "An account with this email already exists, sign in instead?"
            );
          } else {
            setFormErrorText(data.response.data.error);
          }
        } else if (data.response && data.response.status === 500) {
          setFormErrorText("Server error, please try again");
        } else {
          setFormErrorText("Please check your connection");
        }
      }
      setFormSubmitInProgress(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(sessionData)) {
      // identifyUserWithLou(sessionData, LOU);
    }
  }, [sessionData]);

  /**
   * Update field value
   * validate field
   *
   * @param {String} fieldname
   * @param {String} value
   */

  const handleChange = async (fieldname, value) => {
    switch (fieldname) {
      case "firstname":
        setFirstname(value);
        break;
      case "lastname":
        setLastname(value);
        break;
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
    validateFormField(fieldname, value);
    if (fieldname === "email" && formFieldIsValid.Email) {
      const req = await CreateAccountREQ.verifyEmailExists(value);
      if (req.data && req.data.email_exists) {
        setFormFieldIsValid({ ...formFieldIsValid, Email: false });
        setFormErrorText(
          "An account with this email already exists, sign in instead"
        );
      }
    }
  };

  /**
   * Signin with google
   *  - Send user's ID to server to complete google sign-in
   * @param {Object} googleUser
   */

  const googleSignin = async (auth) => {
    const { credential } = auth || {};
    if (credential) {
      const data = await CreateAccountREQ.google(credential);
      if (data.status === "success") {
        storeSession(data.data);
        setSessionData(data.data);
        setFormSubmitInProgress(false);
        setFormIsSubmited(true);
      } else {
        if (data.response && data.response.status === 400) {
          if (data.response.data.error === "duplicate email") {
            setFormErrorText(
              "An account with this email already exists, sign in instead?"
            );
          } else {
            setFormErrorText(data.response.data.error);
          }
        } else if (data.response && data.response.status === 500) {
          setFormErrorText("Server error, please try again");
        } else {
          setFormErrorText("Please check your connection");
        }
      }
    }
  };

  const handleGoogleSigninFailure = (response) => {
    // Notify User Google Signin failed
    // alert('Failed to log in');
    setFormErrorText(response.error.result.message);
  };

  const handleLinkedInSigninFailure = (response) => {
    setFormErrorText(response.errorMessage);
  };

  const handleLinkedInSigninSuccess = async (response) => {
    const data = await CreateAccountREQ.linkedIn(response.code);
    if (data.status === "success") {
      storeSession(data.data);
      setSessionData(data.data);
      setFormSubmitInProgress(false);
      setFormIsSubmited(true);
      setFormIsSubmited(true);
    } else {
      if (data.response && data.response.status === 400) {
        if (data.response.data.error === "duplicate email") {
          setFormErrorText(
            "An account with this email already exists, sign in instead?"
          );
        } else {
          setFormErrorText(data.response.data.error);
        }
      } else if (data.response && data.response.status === 500) {
        setFormErrorText("Server error, please try again");
      } else {
        setFormErrorText("Please check your connection");
      }
    }
  };

  return (
    <div className="FormWrapper">
      <div className="Form">
        <div className="FormInputFieldsWrapper">
          <AuthFormErrorText
            formErrorText={formErrorText}
            setFormErrorText={setFormErrorText}
          />
          <div className="FieldWrapper">
            <TextField
              className="Field"
              value={firstname}
              variant="outlined"
              name="firstname"
              margin="dense"
              label="First name"
              onChange={(e) => handleChange("firstname", e.target.value)}
            />
            <FormFieldValidationTick isValid={formFieldIsValid.Firstname} />
          </div>
          <div className="FieldWrapper">
            <TextField
              className="Field"
              value={lastname}
              margin="dense"
              variant="outlined"
              name="Last name"
              label="Last name"
              onChange={(e) => handleChange("lastname", e.target.value)}
            />
            <FormFieldValidationTick isValid={formFieldIsValid.Lastname} />
          </div>
          <div className="FieldWrapper">
            <TextField
              className="Field"
              value={email}
              variant="outlined"
              name="email"
              margin="dense"
              type="email"
              label="Email address"
              onChange={(e) => handleChange("email", e.target.value)}
            />
            <FormFieldValidationTick isValid={formFieldIsValid.Email} />
          </div>
          <div className="FieldWrapper">
            <CustomPassword password={password} setPassword={setPassword} />
          </div>
        </div>
        <div className="ActionWrapper">
          <span className="Action">
            <Button
              className="Submit"
              onClick={() => handleFormSubmit(formFieldIsValid)}
            >
              {formSubmitInProgress === true ? (
                <BtnLoader
                  border_bottom={"4px solid #FFFFFF"}
                  border_right={"4px solid #FFFFFF"}
                  border_left={"4px solid #838383"}
                  border_top={"4px solid #838383"}
                />
              ) : (
                "Create account"
              )}
            </Button>
            <span className="OrBreaker">
              <Line />Or<Line />
            </span>
            <GoogleAuthButton
              className="Google"
              text="Sign up with Google"
              googleSignin={googleSignin}
              handleGoogleSigninFailure={handleGoogleSigninFailure}
            />
            <LinkedInButton
              className="LinkednButton"
              text="Sign up with Linkedin"
              handleLinkedInSigninSuccess={handleLinkedInSigninSuccess}
              handleLinkedInSigninFailure={handleLinkedInSigninFailure}
            />
          </span>
          <span className="Privacy">
            <span>
              By creating an account, you agree to the Rialto
              <a className="Link" href="/terms">
                {" "}
                Terms of Use
              </a>{" "}
              &{" "}
              <a className="Link" href="/privacy">
                Privacy Policy
              </a>
            </span>
          </span>
          <span className="Signin">
            <Line />
            Already have an account?
            <Line />
          </span>
          <a href="/sign-in">
            <Button className="SigninButton">Sign in</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

const CustomPassword = ({ password, setPassword }) => {
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  return (
    <>
      <TextField
        className="Field"
        value={password}
        variant="outlined"
        margin="dense"
        name="password"
        label="Password"
        type={passwordIsVisible ? "text" : "password"}
        onChange={(e) => setPassword(e.target.value)}
      />
      {passwordIsVisible ? (
        <span className="VisibilityIconWrapper">
          <VisibilityOutlined
            onClick={() => setPasswordIsVisible(false)}
            className="VisibilityIcon"
          />
        </span>
      ) : (
        <span className="VisibilityIconWrapper">
          <VisibilityOffOutlined
            onClick={() => setPasswordIsVisible(true)}
            className="VisibilityIcon"
          />
        </span>
      )}
    </>
  );
};

export default function CreateAccount() {
  const [formSubmitted, setFormIsSubmited] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const width = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
    setScreenWidth(width);
  }, [screenWidth]);

  useEffect(() => {
    if (formSubmitted === true) {
      navigate("/sign-up/confirm");
    }
  }, [formSubmitted, navigate]);

  return (
    <div className="CreateAccountContainer">
      <div className="CreateAccount">
        <div className="CreateAccountBanner">
          <div className="BannerInfo">
            <img
              src={appLogo.desktop}
              className="RialtoLogo"
              alt="Rialto Logo"
            />
            <div>
              <span className="Title">Welcome to Rialto!</span>
              <span className="Content">
                Ready to Hustle Forward with your new business
                community? Create your account to get started!
              </span>
            </div>
          </div>
          <CreateAccountRoot className="CreateAccountRoot" />
        </div>
        <Form
          formSubmitted={formSubmitted}
          setFormIsSubmited={setFormIsSubmited}
        />
      </div>
    </div>
  );
}
