import React from "react";
import { Outlet } from "react-router-dom";
import { useWindowSize } from "../hooks/useWindowSize";
import NeighborhoodNavBar from "../components/RialtoNavBar";
import NeighborhoodBanner from "../components/NeighborhoodBanner";
import NeighborhoodProfile from "../pages/NeighborhoodProfile";

const NeighborhoodInviteLayout = () => {
  const { width } = useWindowSize();
  const isVisible = width < 768;

  return (
    <>
      <NeighborhoodNavBar />
      {!isVisible && (
        <>
          <NeighborhoodBanner />
          <NeighborhoodProfile />
        </>
      )}
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default NeighborhoodInviteLayout;
