import React, { lazy, Suspense } from "react";
import AppLoader from "../components/Loader/loader";
import HomePage from "../pages/LandingPage/HomePage/HomePage";
import Features from "../pages/LandingPage/Features/Features";
import WhyRialto from "../pages/LandingPage/WhyRialto/WhyRialto.js";
import Pricing from "../pages/LandingPage/Pricing/Pricing";
import PrivacyPage from "../pages/LandingPage/Privacy.js";
import TermsOfUse from "../pages/LandingPage/TermsOfUse";
import Explore from "../pages/Explore";
import Neighborhoods from "../pages/Neighborhoods/Neighborhoods.jsx";
import CribSetup from "pages/CribManagement/CribSetup";
import CreateAccountFromInvite from "pages/CreateAccountFromInvite";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<AppLoader />}>
      <Component {...props} />
    </Suspense>
  );

const HoldingScreen = Loadable(
  lazy(() => import("../pages/AttendOfficeHourNew/HoldingScreen/holdingScreen"))
);
const AttendOfficeHours = Loadable(
  lazy(() =>
    import("../pages/AttendOfficeHourNew/AttendOfficeHours/attendOfficeHours")
  )
);

export const mainAppRoutes = [
  {
    path: `/`,
    Component: HomePage,
  },
  {
    path: `/features`,
    Component: Features,
  },
  {
    path: `/whyRialto`,
    Component: WhyRialto,
  },
  {
    path: `/pricing`,
    Component: Pricing,
  },
  {
    path: `/terms`,
    Component: TermsOfUse,
  },
  {
    path: `/privacy`,
    Component: PrivacyPage,
  },
];

export const cribRoutes = [
  {
    path: `/crib/:crib_id`,
    Component: lazy(() =>
      import("../pages/CribManagement/CribProfile/CribProfile")
    ),
  },
  // {
  //   path: `/crib`,
  //   Component: lazy(() => import("../pages/CribProfile")),
  // },
  {
    path: `/crib/:crib_id/edit`,
    Component: lazy(() =>
      import("../components/EditUserProfile/EditUserProfile")
    ),
  },
  {
    path: `/crib/:crib_id/officehour`,
    Component: lazy(() =>
      import("../pages/OfficeHourNew/OfficeHourArchive/officeHourArchive")
    ),
  },
  {
    path: `/crib/:crib_id/setupOfficeHour`,
    Component: lazy(() =>
      import("../pages/OfficeHourNew/OfficeHourForm/officeHourForm")
    ),
  },
  {
    path: `/crib/:crib_id/editOfficeHourNew/:id`,
    Component: lazy(() =>
      import("../pages/OfficeHourNew/EditOfficeHours/editOfficeHourForm")
    ),
  },
  {
    path: `/crib/:crib_id/officehourSuccess`,
    Component: lazy(() =>
      import("../pages/OfficeHourNew/OfficeHourSuccess/success")
    ),
  },
  {
    path: `/crib/:crib_id/officehour/:officehour_id/attend/:available_time_id`,
    Component: lazy(() =>
      import("../pages/ViewOfficeHourDetails/viewOfficeHourDetails")
    ),
  },
  {
    path: `/crib/:crib_id/fav`,
    Component: lazy(() => import("../components/Favorite/Favorite")),
  },
  {
    path: `/crib/:crib_id/neighborhoods`,
    Component: Neighborhoods,
  },
];

export const cribNavigationRoutes = [
  // {
  //   path: `/crib/:crib_id/officehour/holding`,
  //   // path: `/crib/:crib_id/officehour/:officehour_id/attend`
  //   Component: HoldingScreen,
  // },
  {
    path: `crib/:crib_id/officehour/attend`,
    Component: AttendOfficeHours,
  },
  {
    path: `/explore`,
    Component: Explore,
  },
  {
    path: `/setup/crib`,
    Component: CribSetup,
  },
];
