export const sandiChatbot = {
  headerTitle: 'sandi-title',
  toggleButton: 'toggle-button',
  botDisplay: 'bot-display',
  freeTextInput: 'free-text-input',
  freeTextButton: 'free-text-button',
  messageChildBox: 'message-child-box',
  messageOptionsBox: 'message-options-box',
  conversationList: 'conversation-list',
  chatHistory: 'chats',
  botContainer: 'bot-container',
  typingIndicator: 'typing-indicator',
  messageBox: 'message-box',
  optionSelectionToggle: 'option-selection-toggle',
  submitLocationButton: 'submit-location-button',
  selectionChips: 'selection-chips',
  selectedChips: (index) => `selected-chips-${index}`,
};
