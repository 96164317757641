/**
 * Route user on sign in
 * - based on
 *      : account type 
 *      : crib and neighborhood created
 * 
 * @param {String} role
 * @param {Boolean} crib
 * @param {Boolean} neighborhood
 */
import sessionInfo from '../utilities/session/sessionInfo.js'


const routeUser = (role, crib, neighborhood) => {
    let url = "";
    const userSession = sessionInfo();
    let crib_id = userSession.account_id;
    if (!role) {
        // route to select role screen
        url = `${window.location.origin}/sign-up/confirm`;
    }
    else if (!crib) {
        // route to setup crib screen
        url = `${window.location.origin}/setup/crib`;
    } else {
        if (!neighborhood && role === "BCO") {
            url = `${window.location.origin}/setup/neighborhoodprofile/step1`;
            window.onbeforeunload = () => null;
        } else {
            url = `${window.location.origin}/crib/${crib_id}`;
        }
    }
    window.location.href = url;
}

export default routeUser
