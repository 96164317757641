import { Autocomplete } from '@material-ui/lab';
import styled from 'styled-components';
import { COLORS } from 'styles/colors/minwo';
import { InteractiveStyling } from '../MessageContainer/MessageContainer.styles';

export const BotSelectAutoComplete = styled(Autocomplete)`
  & .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
    width: 80%;
    min-height: 38px;
    border-radius: 16px;
    border: 0.31px solid ${COLORS.purple.p500};
    background: ${COLORS.neutral.n1};
    padding: 3px 10px 1px 10px;
  }

  & .MuiInputBase-input {
    font-size: 0.938rem;

    ::placeholder {
      font-size: 0.938rem;
    }
  }

  & .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: unset;
    min-width: 5px;
  }

  & .MuiInput-underline:before {
    transition: none;
    border-bottom: none;
  }

  & .MuiInput-underline {
    padding: 0px 12px;
    min-height: 38px;
    border-radius: 16px;
    border: 0.6px solid ${COLORS.purple.p500};
    background: ${COLORS.gray.gr2};
    font-size: 0.938rem;
  }

  & .MuiOutlinedInput-root {
    border-radius: 16px;
    padding: 4px 12px;
    border: 0.6px solid ${COLORS.purple.p500};
    background: ${COLORS.gray.gr2};
    min-height: 38px;
    display: flex;
    align-items: center;
  }

  & .MuiChip-root.MuiChip-deletable {
    background-color: #c5e0ca;
    height: 28px;
    color: ${COLORS.gray.gray02};
    font-size: 0.938rem;
  }

  &[data-hasselectedstates='true'] {
    & .MuiAutocomplete-inputRoot {
      display: grid;
      justify-content: space-between;
      grid-template-columns: auto 1fr 1fr;
      gap: 3px;
    }
  }
  &[data-hasselectedstates='false'] {
    & .MuiAutocomplete-inputRoot {
      display: grid;
      justify-content: space-between;
      grid-template-columns: auto 1fr;
      gap: 3px;
    }
  }

  & .MuiAutocomplete-inputRoot {
    display: grid;
    justify-content: space-between;
    gap: 3px;
  }
`;

export const PopperIcon = styled.div`
  display: flex;
  justify-self: flex-end;
  cursor: pointer;
`;

export const SelectedOptionSubmitButton = styled.button`
  padding: 8px 24px;
  border-radius: 15.344px;
  border: 0.6px solid rgba(170, 170, 170, 0.8);
  background: rgba(70, 28, 63, 0);
  width: 80%;
  text-align: left;
  color: rgba(170, 170, 170, 0.9);
  font-size: 0.938rem;

  &[data-active='true'] {
    border: 0.6px solid ${COLORS.purple.p500};
    color: ${COLORS.gray.gray02};

    :hover {
      ${InteractiveStyling}
    }
  }
`;
