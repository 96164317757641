import styled from 'styled-components';
import { COLORS } from 'styles/colors/minwo';

export const MenuBox = styled.div`
  border-radius: 16px;
  border: 0.6px solid ${COLORS.purple.p500};
  background: ${COLORS.gray.gr2};
  width: 100%;
  max-height: 160px;
  overflow-y: auto;

  & .MuiAutocomplete-listbox {
    overflow: hidden;
    max-height: unset;
  }
  & .MuiAutocomplete-paper {
    margin: 0;
  }
  & .MuiAutocomplete-option[data-focus="true"],
  & .MuiAutocomplete-option[aria-selected="true"] {
    background-color: unset;
  }

  & .MuiListItem-root.Mui-disabled {
    opacity: 1;
    color: #569480;
  }

  & .MuiMenuItem-root {
    font-size: 0.938rem;

    background-color: unset;

    :hover {
      background-color: unset;
    }
  }

  & .menu_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    width: 100%;

    :hover {
      background-color: unset;
    }
  }
`;
