import React, { forwardRef } from 'react';
import { ChipsWrapper } from './ChipsContainer.styles';
import { Chip } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/svgs/close-icon.svg';
import * as testId from 'utilities/testIds';

export const ChipsContainer = forwardRef((props, ref) => {
  const { value, handleSelectedOptionDelete, getTagProps, disabled } = props;

  return (
    <ChipsWrapper
      className="invisible-scroll"
      ref={ref}
      data-testid={testId.sandiChatbot.selectionChips}
    >
      {value.map((option, index) => (
        <Chip
          key={index}
          label={option}
          onDelete={() => handleSelectedOptionDelete(option)}
          disabled={disabled}
          deleteIcon={<CloseIcon />}
          data-testid={testId.sandiChatbot.selectedChips(index)}
          {...getTagProps}
        />
      ))}
    </ChipsWrapper>
  );
});
