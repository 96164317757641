// zIndex reference. Stacking as needed for reasons
export const zIndexes = [
  "lower-parent", // Reference: (https://stackoverflow.com/a/59785721)
  undefined,
  undefined,
  "menu-heading",
  undefined,
  undefined,
  undefined,
  undefined,
  "scroll-to-top",
  "page-tooltip",
  "page-overlay",
  "overlay-menu",
];
