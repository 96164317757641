import React, { useCallback, useState } from 'react';

import { DropdownSelect } from '../DropdownSelect/DropdownSelect';

import { SingleSelect } from '../SingleSelect/SingleSelect';

export function convertToArray(input) {
  // Split the string using a regular expression that matches semicolons or newlines
  return input
    .split(/[\n;]+/)
    .map((state) => state.trim())
    .filter(Boolean);
}

export function useHasResponse(messages) {
  const hasResponse = useCallback(
    (contentId) => {
      return (
        messages && messages.find((c) => c.contentId === contentId && c.byUser)
      );
    },
    [messages]
  );

  return hasResponse;
}

export function useSubMessageContainerByType(props) {
  const {
    message,
    messageKey, // messageKey is in the format ["id"|"contentId", ...rest]
    handleOptionPost,
    setMessageSentByUser,
    setMessagesToSubmit,
    messageType, // history or present
    messages,
    singleOptionProps,
  } = props;

  const [activeOption, setActiveOption] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);

  const hasResponse = useHasResponse(messages);

  const activeChat = hasResponse(message[messageKey[0]]);
  const activeSingleOption = activeChat || activeOption;
  const activeMultipleOption =
    messageType === 'history' ? activeChat : activeOption;

  const getSelectedOptions = () => {
    if (messageType === 'history' && activeChat)
      return convertToArray(activeChat?.content);
    if (messageType === 'present' && activeOption.length > 0)
      return convertToArray(activeOption);
    return selectedOptions;
  };

  if (!message.options) return null;
  const formattedSelectedOptions = selectedOptions.join(
    `${message?.mergeTkn} `
  );

  const inlineOptProps = {
    options: message.options,
    selectedOptions: getSelectedOptions(),
    setSelectedOptions,
    disabled: !!activeMultipleOption,
    onClick: () => {
      const baseOptionInfo = {
        id: message[messageKey[0]],
        createdAt: new Date(),
      };
      messageType !== 'history' && setActiveOption(formattedSelectedOptions);
      setMessageSentByUser(true);
      setMessagesToSubmit((prevMessages) => [
        ...prevMessages,
        {
          ...baseOptionInfo,
          content: formattedSelectedOptions,
          byUser: true,
        },
      ]);

      const optionObj = {
        ...baseOptionInfo,
        content: selectedOptions,
      };
      handleOptionPost(optionObj);
    },
  };

  const singleOptProps = {
    messages,
    singleOptionProps,
    activeSingleOption,
    handleOptionPost,
    setMessagesToSubmit,
    setMessageSentByUser,
    setActiveOption,
    message,
    messageKey,
  };

  return getOptionRenderComponent({
    optPsst: message.optPsst,
    inlineOptProps,
    singleOptProps,
  });
}

export function getOptionRenderComponent({
  optPsst,
  inlineOptProps,
  singleOptProps,
}) {
  // optPsst could be "INL" | "CHECKBOX"
  switch (optPsst) {
    case 'INL':
      return (
        <DropdownSelect
          buttonText="Select a Location"
          selectPlaceholder="Select"
          {...inlineOptProps}
        />
      );
    default:
      return <SingleSelect {...singleOptProps} />;
  }
}
