import axios from "axios";
import urls from "./baseUrl";
import sessionInfo from "../utilities/session/sessionInfo";

const { token } = sessionInfo();

export const sandiApi = axios.create({
  baseURL: urls.sandi_api_url,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
