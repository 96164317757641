import React from "react";
import { useNavigate } from "react-router-dom";
import checkmark from "../../../assets/Images/congratCheckMark.png";
import "./add-members-outside-rialto.css";

const AddMembersOutsideRialtoSuccess = ({
  successData,
  handleViewDetails,
  neighborhood_id,
}) => {
  const navigate = useNavigate();

  return (
    <div className="add-members-outside-rialto-success">
      <img
        src={checkmark}
        alt="checked-icon"
        className="add-members-outside-rialto-success__icon"
      />
      <div className="add-members-outside-rialto-success__text--container">
        <p className="add-members-outside-rialto-success__text">
          {successData?.message}
        </p>
      </div>
      <div className="add-members-outside-rialto-success__btn--container">
        <button
          onClick={() => navigate(`/neighborhood/${neighborhood_id}/members`)}
          className="add-members-outside-rialto-success__btn filled"
        >
          View Members
        </button>
        <button
          className="add-members-outside-rialto-success__btn outlined"
          onClick={handleViewDetails}
        >
          See Details
        </button>
      </div>
    </div>
  );
};

export default AddMembersOutsideRialtoSuccess;
