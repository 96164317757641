import React, { useEffect, useState } from 'react';
import { ReactComponent as SignInRoot } from '../assets/Icons/CreateAccountRoot.svg';
import API from '../api/Account.js';
import inputValidator from '../utilities/inputValidator.js';
import '../styles/pages/ForgotPassword.css';
import { appLogo } from 'config';

const TickSuccess = () => {
	return (
		<svg
			className='FormFieldTick'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM8 12.17L14.59 5.58L16 7L8 15L4 11L5.41 9.59L8 12.17Z'
				fill='#3B7A71'
			/>
		</svg>
	);
};

const TickError = () => {
	return (
		<svg
			className='FormFieldTick'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 8.59L13.59 5L15 6.41L11.41 10L15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59Z'
				fill='#EB5757'
			/>
		</svg>
	);
};

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [validEmail, setEmailValid] = useState(null);

	const handleChange = (e) => {
		setEmail(e.target.value);
	};

	const requestPasswordResetLink = (e) => {
		e.preventDefault();
		(async () => {
			if (validEmail === true) {
				const res = await API.requestPasswordResetLink(email);
				if (res.status === 'success') {
					window.location.href = `${window.location.origin}/forgot-password-confirmation`;
				}
			}
		})();
	};

	useEffect(() => {
		if (email.length > 0) {
			const email_is_valid =
				inputValidator(email, { isEmail: true }) && email.length > 0;
			setEmailValid(email_is_valid);
			(async () => {
				if (validEmail === true) {
					const res = await API.verifyEmailExists(email);
					if (res.status === 'success') {
						console.log(res.data);
						const email_exists = res.data.email_exists;
						setEmailValid(email_exists);
					}
				}
			})();
		}
	}, [email]);

	return (
		<div className='forgot-password'>
			<div className='forgot-password__banner'>
				<div className='forgot-password__banner--container'>
					<div className='forgot-password__banner--content'>
						<div className='forgot-password__logo--container'>
							<img src={appLogo.desktop} className='RialtoLogo' alt='' />
						</div>
						<div className='forgot-password__banner--info'>
							<span className='forgot-password__banner--title'>
								Reset your password
							</span>
							<span className='forgot-password__banner--sub'>
								Confirm your email address to recover your account.
							</span>
						</div>
					</div>
					<SignInRoot className='SignInRoot' />
				</div>
			</div>
			<div className='forgot-password__form--flex'>
				<div className='forgot-password__form--container'>
					<h1 className='forgot-password__form--heading'>
						Please enter your email address to recover your account
					</h1>
					<form
						className='forgot-password__form'
						onSubmit={requestPasswordResetLink}
					>
						<div className='form__input--container'>
							<input
								className='form__input'
								type='email'
								name='email'
								placeholder='Email address'
								value={email}
								onChange={(e) => handleChange(e)}
							/>
							{validEmail === null ? (
								<></>
							) : validEmail === true ? (
								<TickSuccess className='FormFieldTick' />
							) : (
								<TickError className='FormFieldTick' />
							)}
						</div>
						<button
							disabled={!validEmail}
							className='Submit'
							onClick={requestPasswordResetLink}
						>
							Submit
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
