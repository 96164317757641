import React, { useEffect, useState } from 'react';
import { ReactComponent as SignInRoot } from '../assets/Icons/CreateAccountRoot.svg';
import API from '../api/Account.js';
import '../styles/pages/ResetPassword.css';
import { useParams } from 'react-router-dom';
import { appLogo } from 'config';

const TickSuccess = () => {
	return (
		<svg
			className='FormFieldTick'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM8 12.17L14.59 5.58L16 7L8 15L4 11L5.41 9.59L8 12.17Z'
				fill='#3B7A71'
			/>
		</svg>
	);
};

const TickError = () => {
	return (
		<svg
			className='FormFieldTick'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 8.59L13.59 5L15 6.41L11.41 10L15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59Z'
				fill='#EB5757'
			/>
		</svg>
	);
};

const ResetPassword = () => {
	const [password1, setPassword1] = useState('');
	const [password2, setPassword2] = useState('');
	const [password1Valid, setPassword1Valid] = useState(false);
	const [password2Valid, setPassword2Valid] = useState(false);
	const { token } = useParams();
	console.log('token', token);
	const resetUserPassword = async (e) => {
		e.preventDefault();
		const payload = { password: password2, token };
		const res = await API.resetPassword(payload);
		if (res.status === 'success') {
			alert('Your password has been succesfully reset, please sign-in');
			window.location.href = `${window.location.origin}/sign-in`;
		}
	};

	useEffect(() => {
		if (password1.length > 0) {
			const password1_valid = password1.length >= 8;
			setPassword1Valid(password1_valid);
		}
		if (password2.length > 0) {
			const password2_valid = password2.length >= 8 && password1 === password2;
			setPassword2Valid(password2_valid);
		}
	}, [password1, password2]);

	return (
		<div className='reset-password'>
			<div className='reset-password__banner'>
				<div className='reset-password__banner--container'>
					<div className='reset-password__banner--content'>
						<div className='reset-password__logo--container'>
							<img src={appLogo.desktop} className='RialtoLogo' alt='' />
						</div>
						<div className='reset-password__banner--info'>
							<span className='reset-password__banner--title'>
								Reset password
							</span>
							<span className='reset-password__banner--sub'>
								Create a new password for your account.
							</span>
						</div>
					</div>
					<SignInRoot className='SignInRoot' />
				</div>
			</div>
			<div className='reset-password__form--flex'>
				<div className='reset-password__form--container'>
					<h1 className='reset-password__form--heading'>
						Enter your new password
					</h1>
					<form className='reset-password__form'>
						<div className='form__input--container'>
							<input
								className='form__input'
								type='password'
								placeholder='New password'
								value={password1}
								onChange={(e) => setPassword1(e.target.value)}
							/>
							{password1.length === 0 ? (
								<></>
							) : password1Valid ? (
								<TickSuccess className='FormFieldTick' />
							) : (
								<TickError className='FormFieldTick' />
							)}
						</div>
						<div className='form__input--container'>
							<input
								className='form__input'
								type='password'
								placeholder='Confirm password'
								value={password2}
								onChange={(e) => setPassword2(e.target.value)}
							/>
							{password2.length === 0 ? (
								<></>
							) : password2Valid ? (
								<TickSuccess className='FormFieldTick' />
							) : (
								<TickError className='FormFieldTick' />
							)}
						</div>
						<button
							disabled={!password2Valid}
							onClick={resetUserPassword}
							className='Submit'
						>
							Reset Password
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
