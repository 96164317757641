import React, { forwardRef } from 'react';
import { MessageParentBox, MessageBox } from './MessageContainer.styles';
import DOMPurify from 'dompurify';
import * as testId from 'utilities/testIds';
import { DomPurifyConfig } from 'config';
import { useSubMessageContainerByType } from './MessageContainer.utils';

export const MessageContainer = forwardRef((props, ref) => {
  const {
    message,
    messageKey,
    handleOptionPost,
    setMessageSentByUser,
    setMessagesToSubmit,
    messageType,
    messages,
  } = props;

  const splitContent = message && message?.content?.split('\n');
  const formattedContent =
    message && message.byUser
      ? splitContent.join(`${message.mergeTkn} `)
      : message.content;

  const subMessageContainer = useSubMessageContainerByType({
    message,
    messageKey,
    handleOptionPost,
    setMessageSentByUser,
    setMessagesToSubmit,
    messages,
    messageType,
    singleOptionProps: {
      'data-testid': testId.sandiChatbot.messageOptionsBox,
    },
  });

  return (
    <MessageParentBox ref={ref} data-testid={testId.sandiChatbot.messageBox}>
      <MessageBox
        data-by-user={message?.byUser}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(formattedContent, DomPurifyConfig),
        }}
        data-testid={testId.sandiChatbot.messageChildBox}
      />
      {subMessageContainer}
    </MessageParentBox>
  );
});
