import axios from 'axios';
import urls from './baseUrl.js'

const base_url = urls.base_url;

const SignIn = {
    async linkedIn(code) {
        try {
            const data = await axios.post(base_url+'auth/sign-in/linkedin', { code });            
            return data;
        } catch (error) {
            return error;
        }
    },
    async google(id_token) {
        try {
            const data = await axios.post(base_url+'auth/sign-in/google', {'token': id_token});
            return data;
        } catch (error) {
            return error;
        }
    },
    async form(formData) {
        try {        
           const data = await axios.post(base_url+'auth/sign-in', formData);
            return data;
        } catch (error) {
            return error;
        }
    }
}

export default SignIn;
