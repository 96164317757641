import axios from "axios";
import urls from "./baseUrl";
import parseForm from "../propsData/FormParser/neighborhood";
import sessionInfo from "../utilities/session/sessionInfo";
import store from "../utilities/storage";
import storeSession from "../utilities/session/sessionInitialIzer";

// TODO: split into neighborhood profile, members, bulletin and forum files

let { token } = sessionInfo();

const neighborhood = {
  getNeighborhood: async (neighborhood_id) => {
    const url = `${urls.base_url}neighborhood/${neighborhood_id}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  setupProfile: async (forms) => {
    const data = parseForm.neighborhood_profile(forms);
    try {
      const resp = await axios.post(
        `${urls.base_url}neighborhood/setup/profile`,
        data,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
      store.write("neighborhood_created", true);
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  updateProfile: async (forms) => {
    const data = parseForm.neighborhood_profile(forms);
    const neighborhood_id = store.retrieve("neighborhood_id");
    try {
      const resp = await axios.put(
        `${urls.base_url}neighborhood/${neighborhood_id}`,
        data,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },

  addMembers: async (data, neighborhood_id) => {
    const neighborhood_name = store.retrieve("neighborhood_name");

    const payload = { members: data, neighborhood_name };
    try {
      const resp = await axios.post(
        `${urls.base_url}neighborhood/${neighborhood_id}/members/invite`,
        payload,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },

  inviteNewMembers: async (payload, neighborhood_id) => {
    try {
      const resp = await axios.post(
        `${urls.base_url}neighborhood/${neighborhood_id}/members/invite/new_users`,
        payload,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },

  inviteExisitingMembers: async (data, neighborhood_id) => {
    try {
      const resp = await axios.post(
        `${urls.base_url}neighborhood/${neighborhood_id}/members/invite/existing_users`,
        data,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },

  inviteBulkMembers: async ({ formData, neighborhood_id, setProgress }) => {
    try {
      const url = `${urls.base_url}neighborhood/${neighborhood_id}/members/invite/new_users/file_upload`;
      const resp = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer: ${token}`,
          // "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event) => {
          const progressPercent = Math.round(event.loaded / event.total) * 100;
          setProgress(progressPercent);
        },
      });
      return resp.data;
    } catch (error) {
      return error;
    }
  },

  getInviteLink: async (neighborhood_id) => {
    const { token } = sessionInfo();
    try {
      const resp = await axios.post(
        `${urls.base_url}neighborhood/${neighborhood_id}/members/invite/link`,
        {},
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },

  getNeighborhoodMembers: async (neighborhood_id) => {
    let invite_id = store.retrieve("invite_id");
    let i_neighborhood_id = store.retrieve("i_neighborhood_id");
    const { token } = sessionInfo();
    if (invite_id) {
      neighborhood_id = i_neighborhood_id;
    }
    const url = `${urls.base_url}neighborhood/${neighborhood_id}/members`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  getMembersWithinRialto: async (neighborhood_id, page, limit) => {
    const { token } = sessionInfo();
    try {
      const resp = await axios.get(
        `${urls.base_url}neighborhood/${neighborhood_id}/members/crib/search?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  getMembersViaNameWithinRialto: async (neighborhood_id, query) => {
    const { token } = sessionInfo();
    try {
      const resp = await axios.get(
        `${urls.base_url}neighborhood/${neighborhood_id}/members/crib/search?fullname=${query}`,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  getMembersViaEmailWithinRialto: async (neighborhood_id, query) => {
    const { token } = sessionInfo();
    try {
      const resp = await axios.get(
        `${urls.base_url}neighborhood/${neighborhood_id}/members/crib/search?email=${query}`,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  addBulletin: async (forms) => {
    const data = parseForm.bulletin(forms);
    try {
      const resp = await axios.post(
        `${urls.base_url}neighborhood/setup/bulletin`,
        data,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  addForum: async (forms) => {
    const data = parseForm.forum(forms);
    try {
      const resp = await axios.post(
        `${urls.base_url}neighborhood/setup/forum`,
        data,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  update_bulletin: async (forms) => {
    const data = parseForm.bulletin(forms);
    try {
      const resp = await axios.put(
        `${urls.base_url}neighborhood/setup/bulletin`,
        data,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  update_forum: async (forms) => {
    const data = parseForm.forum(forms);
    try {
      const resp = await axios.put(
        `${urls.base_url}neighborhood/setup/forum`,
        data,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  updateRank: async (data) => {
    const updateRankResponse = await axios.post(
      `${urls.base_url}neighborhood/${data.neighborhood_id}/members_rank`,
      data,
      {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      }
    );

    return updateRankResponse.data;
  },
  getNeighborhoodImage: async (neighborhood_id) => {
    try {
      const resp = await axios.get(
        `${urls.base_url}neigborhood/image/${neighborhood_id}`,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
      return await axios.get(resp.data);
    } catch (error) {
      return null;
    }
  },
  getNeighborhoodImageUrl: async (neighborhood_id) => {
    console.log({ neighborhood_id });
    try {
      const resp = await axios.get(
        `${urls.base_url}neigborhood/image/${neighborhood_id}`,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return null;
    }
  },
};

export default neighborhood;
