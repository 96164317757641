import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import storeSession from "../../utilities/session/sessionInitialIzer.js";
import isEmpty from "../../utilities/isEmpty.js";
import SignInREQ from "../../api/Signin.js";
import AuthFormErrorText from "../AuthFormErrorText.js";
import BtnLoader from "../Loader/btn-loader.jsx";
import { ReactComponent as Refresh } from "../../assets/Icons/refresh.svg";
import { ReactComponent as Line } from "../../assets/Icons/Line.svg";
// import identifyUserWithLou from "../../utilities/session/identifyUserWithLou.js";
import "./ExpiredSession.css";
import GoogleAuthButton from "components/auth/GoogleButton/index.jsx";
import { LinkedInButton } from "components/auth/LinkedInButton/index.jsx";
import { useRialtoAPIConnectionEstablished } from '../../utilities/session/checkConnectionEstablished.js';
import { useCheckCookiesEnabled } from '../../utilities/session/checkCookiesEnabled.js';

const ExpiredSession = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sessionData, setSessionData] = useState(null);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const { checkRialtoAPIConnectionStatus } = useRialtoAPIConnectionEstablished();
  const { cookiesEnabled } = useCheckCookiesEnabled();

  if (cookiesEnabled == false) {
    const error_message =
      'Please enable cookies ' +
      'in your browser settings to continue using this application.';
    setFormError(error_message);
  }

  let query = useQuery();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      email,
      password,
    };

    setIsLoading(true);
    const res = await SignInREQ.form(formData);
    setIsLoading(false);
    if (res?.status === 200) {
      storeSession(res?.data?.data);
      setSessionData(res?.data?.data);
      const rerouteUrl = query.get("ref");
      window.location.replace(rerouteUrl);
    } else {
      const connected = await checkRialtoAPIConnectionStatus();
      if (connected === false) {
        setFormError(
          'A network error occurred. Please check your internet connection and try again.',
        );
      } else {
        if (res.response && res.response.status === 400) {
          setFormError(res.response.data.error);
        } else if (res.response && res.response.status === 500) {
          setFormError("Server error, please try again");
        } else {
          setFormError("Please check your connection");
        }
      }
    }
  };

  const handleGoogleSignin = async (auth) => {
    const { credential } = auth || {};
    if (credential) {
      const res = await SignInREQ.google(credential);
      if (res.status === 200) {
        storeSession(res?.data?.data);
        setSessionData(res?.data?.data);
        const rerouteUrl = query.get("ref");
        window.location.replace(rerouteUrl);
      } else {
        const connected = await checkRialtoAPIConnectionStatus();
        if (connected === false) {
          setFormError(
            'A network error occurred. Please check your internet connection and try again.',
          );
        } else {
          if (res.response && res.response.status === 400) {
            setFormError(res.response.data.error);
          } else if (res.response && res.response.status === 500) {
            setFormError("Server error, please try again");
          } else {
            setFormError("Network error. Check your connection.");
          }
        }
      }
      setIsLoading(false);
      // setFormIsSubmited(true);
    }
  };

  const handleGoogleSigninFailure = (response) => {
    // Notify User Google Signin failed
    if (isLoading === true) {
      setFormError(response.error);
    }
  };

  const handleLinkedInSigninSuccess = async (response) => {
    const res = await SignInREQ.linkedIn(response.code);

    if (res.status === 200) {
      storeSession(res.data.data);
      setSessionData(res?.data?.data);
      const rerouteUrl = query.get("ref");
      window.location.replace(rerouteUrl);
    } else {
      const connected = await checkRialtoAPIConnectionStatus();
      if (connected === false) {
        setFormError(
          'A network error occurred. Please check your internet connection and try again.',
        );
      } else {
        if (res.data && res.data.status === "error") {
          // notify app error
          setFormError(res.data.data);
        } else {
          if (res.response && res.response.status === 400) {
            setFormError(res.response.data.error);
          } else if (res.response && res.response.status === 500) {
            setFormError("Server error, please try again");
          } else {
            setFormError("Please check your connection");
          }
        }
      }
    }
    setIsLoading(false);
    // setFormIsSubmited(true);
  };

  const handleLinkedInSigninFailure = (response) => {
    setFormError(response.errorMessage);
  };

  useEffect(() => {
    if (!isEmpty(sessionData)) {
      // identifyUserWithLou(sessionData, LOU);
    }
  }, [sessionData]);

  return (
    <div className="expired-session">
      <div className="expired-session__container">
        <div className="expired-session__svg--container">
          <Refresh />
        </div>
        <h3 className="expired-session__heading">
          You’re currently logged out.
        </h3>
        <h3 className="expired-session__heading">Log back in to continue.</h3>
        <form className="expired-session__form" onSubmit={handleFormSubmit}>
          <AuthFormErrorText
            formErrorText={formError}
            setFormErrorText={setFormError}
          />
          <input
            type="email"
            className="expired-session__input"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            className="expired-session__input"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ "margin-bottom": "0" }}
          />
          <span className="expired-session__forgot-password">
            <a className='Link' href='/forgot-password'>
              Forgot password?
            </a>
          </span>
          <button className="expired-session__btn">
            {isLoading ? (
              <BtnLoader
                border_bottom={"4px solid #FFFFFF"}
                border_right={"4px solid #FFFFFF"}
                border_left={"4px solid #838383"}
                border_top={"4px solid #838383"}
              />
            ) : (
              "Sign in"
            )}
          </button>
        </form>
        <p className="expired-session__text"></p>
        <span className="expired-session__breaker">
          <Line />Or sign in with<Line />
        </span>
        <div className="expired-session__btn--container">
          <GoogleAuthButton
            className="Google"
            text="Sign in with Google"
            googleSignin={handleGoogleSignin}
            handleGoogleSigninFailure={handleGoogleSigninFailure}
          />
          <LinkedInButton
            className="LinkednButton"
            text="Sign in with Linkedin"
            handleLinkedInSigninSuccess={handleLinkedInSigninSuccess}
            handleLinkedInSigninFailure={handleLinkedInSigninFailure}
          />
        </div>
      </div>
    </div>
  );
};

export default ExpiredSession;
