import React from "react";
import { Link } from "react-router-dom";
import checkmark from "../../../assets/Images/congratCheckMark.png";
import "./import-members.css";

const ImportMembersSuccess = ({
  handleClose,
  responseData,
  neighborhood_id,
  handleViewDetails,
}) => {
  return (
    <div className="import-members-success">
      <div className="import-members-success__conatiner">
        <img
          src={checkmark}
          alt="checked-icon"
          className="import-members-success__icon"
        />
        <h2 className="import-members-success__heading">
          File Uploaded Successfully
        </h2>
        <p className="import-members-success__sub">{responseData?.message}</p>
        <div className="import-members-success__btn--container">
          <button
            className="import-members-success__btn filled"
            onClick={handleViewDetails}
          >
            View Details
          </button>
          <Link
            to={`/neighborhood/${neighborhood_id}/members`}
            className="import-members-success__btn outlined"
            onClick={handleClose}
          >
            Done
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ImportMembersSuccess;
