import React, { Component } from 'react';
import MemberUploadSuccess from '../assets/Icons/MemberUploadSuccess.svg';
import { ReactComponent as SignInRoot } from '../assets/Icons/CreateAccountRoot.svg';
import '../styles/pages/PasswordResetSuccess.css';
import { appLogo } from 'config';

export default class PasswordResetSuccess extends Component {
	render() {
		return (
			<div className='password-reset-success'>
				<div className='password-reset-success__banner'>
					<div className='password-reset-success__banner--container'>
						<div className='password-reset-success__banner--content'>
							<div className='password-reset-success__logo--container'>
								<img src={appLogo.desktop} className='RialtoLogo' alt='' />
							</div>
							<div className='password-reset-success__banner--info'>
								<span className='password-reset-success__banner--title'>
									Password reset successful
								</span>
								<span className='password-reset-success__banner--sub'>
									Your account has been recovered successfully.
								</span>
							</div>
						</div>
						<SignInRoot className='SignInRoot' />
					</div>
				</div>
				<div className='password-reset-success__flex'>
					<div className='password-reset-success__container'>
						<h2 className='password-reset-success__heading'>
							Congratulations!
						</h2>
						<div className='password-reset-success__icon--container'>
							<img
								src={MemberUploadSuccess}
								className='password-reset-success__icon'
								alt=''
							/>
						</div>
						<h3 className='password-reset-success__subheading'>
							Password reset successful. You can now sign in to your Rialto
							account with your new password.
						</h3>
						<button className='Submit'>Sign in</button>
					</div>
				</div>
			</div>
		);
	}
}
