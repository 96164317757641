import React, { useState, useEffect } from "react";
import { ReactComponent as GoogleIcon } from "../../../assets/svgs/GoogleIcon.svg";
import "./GoogleAuthButton.css";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GoogleAuthButton = ({ className, text, googleSignin }) => {
  const [init, setInit] = useState(undefined);

  useEffect(() => {
    const createHiddenGoogleWrapper = () => {
      const hiddenGoogleBtn = document.querySelector("#hiddenGoogleBtn");
      window.google.accounts.id.renderButton(hiddenGoogleBtn, { type: "icon" });

      return {
        click: () => {
          hiddenGoogleBtn.querySelector("div[role=button]").click();
        },
      };
    };

    window.onload = function () {
      window.google.accounts.id.initialize({
        client_id: CLIENT_ID,
        callback: googleSignin,
      });

      const hiddenGoogleWrapper = createHiddenGoogleWrapper();
      window.handleGoogleLogin = () => {
        if (hiddenGoogleWrapper) hiddenGoogleWrapper.click();
      };
    };

    return () => setInit(true);
    // eslintDisable-next-line react-hooks/exhaustive-deps
  }, [init]);

  return (
    <>
      <div
        id="googleButtonWrapper"
        onClick={window.handleGoogleLogin}
        className={className || ""}
      >
        <div id="googleBtn">
          <GoogleIcon />
          <span className="buttonText">{text}</span>
        </div>
        <div id="hiddenGoogleBtn" style={{ display: "none" }}></div>
      </div>
    </>
  );
};

export default GoogleAuthButton;
