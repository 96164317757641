import React, { useState, useEffect } from 'react';
import '../styles/pages/SignIn.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { ReactComponent as SignInRoot } from '../assets/Icons/CreateAccountRoot.svg';
import { ReactComponent as VisibilityOffOutlined } from '../assets/Icons/visibility_24px_outlined.svg';
import { ReactComponent as VisibilityOutlined } from '../assets/Icons/visibility_off_24px_outlined.svg';
import { ReactComponent as Line } from '../assets/Icons/Line.svg';
import SignInREQ from '../api/Signin.js';
import AuthFormErrorText from '../components/AuthFormErrorText.js';
import storeSession from '../utilities/session/sessionInitialIzer.js';
// import identifyUserWithLou from "../utilities/session/identifyUserWithLou.js"
import isEmpty from '../utilities/isEmpty';
import routeUser from '../utilities/routeUser.js';
import store from '../utilities/storage';
import BtnLoader from '../components/Loader/btn-loader';
import { useRialtoAPIConnectionEstablished } from '../utilities/session/checkConnectionEstablished.js';
import { useCheckCookiesEnabled } from '../utilities/session/checkCookiesEnabled.js';
import GoogleAuthButton from '../components/auth/GoogleButton/index.jsx';
import { LinkedInButton } from 'components/auth/LinkedInButton';
import { appLogo } from 'config';
import FormFieldValidationTick from 'components/FormFieldValidationTick';

/**
 *  Signup form containing
 *  - basic form
 *  - google signup button
 *  - linkedIn signup button
 */
const Form = ({ formSubmitted, setFormIsSubmited }) => {
  const defaultFormValidStatus = {
    Email: undefined,
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formFieldIsValid, setFormFieldIsValid] = useState(
    defaultFormValidStatus
  );
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [formErrorText, setFormErrorText] = useState('');
  const [sessionData, setSessionData] = useState(null);

  const baseUrl = `${window.location.protocol}//${window.location.host}/linkedin`;

  const { checkRialtoAPIConnectionStatus } =
    useRialtoAPIConnectionEstablished();

  const { cookiesEnabled } = useCheckCookiesEnabled();

  if (cookiesEnabled == false) {
    const error_message =
      'Please enable cookies ' +
      'in your browser settings to continue using this application.';
    setFormErrorText(error_message);
  }

  /**
   * Check if email is valid
   * @param {String}: email
   * @returns {boolean}: true/false
   */
  const isValidEmailFormat = (email) => {
    let format =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email && email.match(format)) {
      return true;
    }
    return false;
  };

  /**
   * Validate field by type
   * @param {String} fieldname
   * @param {String} value
   */
  const validateFormField = (fieldname, value) => {
    switch (fieldname) {
      case 'email':
        setFormFieldIsValid({
          ...formFieldIsValid,
          Email: isValidEmailFormat(value),
        });
        break;
      default:
        break;
    }
  };

  /**
   * Create user account
   * - send user info to server
   *
   * @param {String} fieldname
   * @param {String} value
   */

  const handleFormSubmit = async (FormFieldIsValid) => {
    const { Email } = FormFieldIsValid;
    const formIsValidated = Email ? true : false;

    if (formIsValidated) {
      setFormSubmitInProgress(true);
      const formData = {
        email: email,
        password: password,
      };

      const res = await SignInREQ.form(formData);
      if (res.status === 200) {
        storeSession(res.data.data);
        setSessionData(res.data.data);
        routeUser(
          res.data.data.role,
          res.data.data.crib,
          res.data.data.neighborhood
        );
      } else {
        const connected = await checkRialtoAPIConnectionStatus();
        if (connected === false) {
          setFormErrorText(
            'A network error occurred. Please check your internet connection and try again.'
          );
        } else {
          if (res.response && res.response.status === 400) {
            setFormErrorText(res.response.data.error);
          } else if (res.response && res.response.status === 500) {
            setFormErrorText('Server error, please try again later');
          } else {
            setFormErrorText('Server error, please try again later');
          }
        }
      }
      setFormSubmitInProgress(false);
      setFormIsSubmited(true);
    }
  };

  /**
   * Update field value
   * validate field
   *
   * @param {String} fieldname
   * @param {String} value
   */
  const handleChange = (fieldname, value) => {
    switch (fieldname) {
      case 'email':
        setEmail(value);
        break;
      default:
        break;
    }
    validateFormField(fieldname, value);
  };

  /**
   * Signin with google
   *  - Send user's ID to server to complete google sign-in
   * @param {Object} response
   */

  const googleSignin = async (response) => {
    const { credential } = response || {};
    if (credential) {
      const res = await SignInREQ.google(credential);

      if (res.status === 200) {
        storeSession(res.data.data);
        setSessionData(res.data.data);
        routeUser(
          res.data.data.role,
          res.data.data.crib,
          res.data.data.neighborhood
        );
      } else {
        if (res.response && res.response.status === 400) {
          setFormErrorText(res.response.data.error);
        } else if (res.response && res.response.status === 500) {
          setFormErrorText('Server error, please try again');
        } else {
          setFormErrorText('Network error. Check your connection.');
          // notify connection error
        }
      }
      setFormSubmitInProgress(false);
      setFormIsSubmited(true);
    }
  };

  const handleGoogleSigninFailure = (response) => {
    // Notify User Google Signin failed
    if (formSubmitInProgress) {
      setFormErrorText(response.error);
    }
  };

  const handleLinkedInSigninFailure = (response) => {
    if (formSubmitInProgress) {
      setFormErrorText(response.errorMessage);
    }
  };

  const handleLinkedInSigninSuccess = async (response) => {
    const res = await SignInREQ.linkedIn(response.code);

    if (res.status === 200) {
      storeSession(res.data.data);
      setSessionData(res.data.data);
      routeUser(
        res.data.data.role,
        res.data.data.crib,
        res.data.data.neighborhood
      );
    } else {
      if (res.data && res.data.status === 'error') {
        // notify app error
        setFormErrorText(res.data.data);
      } else {
        if (res.response && res.response.status === 400) {
          setFormErrorText(res.response.data.error);
        } else if (res.response && res.response.status === 500) {
          setFormErrorText('Server error, please try again');
        } else {
          setFormErrorText('Please check your connection');
        }
      }
    }
    setFormSubmitInProgress(false);
    setFormIsSubmited(true);
  };

  useEffect(() => {
    if (!isEmpty(sessionData)) {
      // identifyUserWithLou(sessionData, LOU);
    }
  }, [sessionData]);

  return (
    <div className="FormWrapper">
      <div className="Form">
        <div className="FormInputFieldsWrapper">
          <AuthFormErrorText
            formErrorText={formErrorText}
            setFormErrorText={setFormErrorText}
          />
          <div className="FieldWrapper">
            <TextField
              className="Field"
              value={email}
              variant="outlined"
              name="email"
              margin="dense"
              type="email"
              label="Email address"
              onChange={(e) => handleChange('email', e.target.value)}
            />
            <FormFieldValidationTick isValid={formFieldIsValid.Email} />
          </div>
          <div className="FieldWrapper">
            <CustomPassword password={password} setPassword={setPassword} />
          </div>
          <span className="ForgotPassword">
            <a className="Link" href="/forgot-password">
              Forgot password?
            </a>
          </span>
        </div>
        <div className="ActionWrapper">
          <div className="Action">
            <Button
              className="Submit"
              onClick={() => handleFormSubmit(formFieldIsValid)}
              disabled={formSubmitInProgress ? true : false}
            >
              {formSubmitInProgress === true ? (
                <BtnLoader
                  border_bottom={'4px solid #FFFFFF'}
                  border_right={'4px solid #FFFFFF'}
                  border_left={'4px solid #838383'}
                  border_top={'4px solid #838383'}
                />
              ) : (
                'Sign in'
              )}
            </Button>
            <span className="OrBreaker">
              <Line />
              Or
              <Line />
            </span>
            <GoogleAuthButton
              className="Google"
              text="Sign in with Google"
              googleSignin={googleSignin}
              handleGoogleSigninFailure={handleGoogleSigninFailure}
            />
            <LinkedInButton
              className="LinkednButton"
              text="Sign in with Linkedin"
              handleLinkedInSigninSuccess={handleLinkedInSigninSuccess}
              handleLinkedInSigninFailure={handleLinkedInSigninFailure}
            />
          </div>
          <span className="CreateAccount">
            <Line />
            Don’t have an account?
            <Line />
          </span>
          <a href="sign-up">
            <Button className="CreateAccountButton">
              Create a new account
            </Button>
          </a>
          <div className="Privacy">
            <span className="">
              By creating an account, you agree to the Rialto
              <a className="Link" href="/terms">
                &nbsp; Terms of Use
              </a>
              &nbsp;&&nbsp;
              <a className="Link" href="/privacy">
                Privacy Policy
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomPassword = ({ password, setPassword }) => {
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  return (
    <>
      <TextField
        className="Field"
        value={password}
        variant="outlined"
        margin="dense"
        name="password"
        label="Password"
        type={passwordIsVisible ? 'text' : 'password'}
        onChange={(e) => setPassword(e.target.value)}
      />
      {passwordIsVisible ? (
        <span className="VisibilityIconWrapper">
          <VisibilityOutlined
            onClick={() => setPasswordIsVisible(false)}
            className="VisibilityIcon"
          />
        </span>
      ) : (
        <span className="VisibilityIconWrapper">
          <VisibilityOffOutlined
            onClick={() => setPasswordIsVisible(true)}
            className="VisibilityIcon"
          />
        </span>
      )}
    </>
  );
};

export default function SignIn() {
  if (store.retrieve('setShowChat')) {
    store.remove('setShowChat');
  }

  const [formSubmitted, setFormIsSubmited] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const width = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
    return setScreenWidth(width);
  }, [screenWidth]);

  return (
    <div className="SignInContainer">
      <div className="SignIn">
        <div className="SignInBanner">
          <div className="BannerInfo">
            <img
              src={appLogo.desktop}
              className="RialtoLogo"
              alt="Rialto Logo"
            />
            <div>
              <span className="Title">Welcome to Rialto!</span>
              <span className="Content">
                Ready to hustle forward with your new business community? Sign
                in to get started!
              </span>
            </div>
            {/* </div> */}
          </div>
          <SignInRoot className="SignInRoot" />
        </div>
        <Form
          formSubmitted={formSubmitted}
          setFormIsSubmited={setFormIsSubmited}
        />
      </div>
    </div>
  );
}
