import React, { useState, useEffect } from 'react';
import NavBar from '../components/RialtoNavBar';
import Button from '@material-ui/core/Button';
import { ReactComponent as CreateAccountRoot } from '../assets/Icons/CreateAccountRoot.svg';
import api from '../api/Account.js';
import storeSession from '../utilities/session/sessionInitialIzer.js';
import { useNavigate } from 'react-router-dom';
import sessionInfo from '../utilities/session/sessionInfo';
import isEmpty from '../utilities/isEmpty';
// import identifyUserWithLou from "../utilities/session/identifyUserWithLou";
import '../styles/components/SignupConfirmModal.css';
import '../styles/pages/CreateAccount.css';
import { appLogo } from 'config';

const TickSelect = () => {
	return (
		<svg
			className='TickSelect'
			width='14'
			height='14'
			viewBox='0 0 14 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g opacity='0.55'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M6.99999 1.16675C3.77999 1.16675 1.16666 3.78008 1.16666 7.00008C1.16666 10.2201 3.77999 12.8334 6.99999 12.8334C10.22 12.8334 12.8333 10.2201 12.8333 7.00008C12.8333 3.78008 10.22 1.16675 6.99999 1.16675ZM6.99999 11.6667C4.42749 11.6667 2.33332 9.57258 2.33332 7.00008C2.33332 4.42758 4.42749 2.33341 6.99999 2.33341C9.57249 2.33341 11.6667 4.42758 11.6667 7.00008C11.6667 9.57258 9.57249 11.6667 6.99999 11.6667ZM5.83332 8.26591L9.67749 4.42175L10.5 5.25008L5.83332 9.91675L3.49999 7.58341L4.32249 6.76091L5.83332 8.26591Z'
					fill='#444444'
				/>
			</g>
		</svg>
	);
};

const TickDormant = () => {
	return (
		<svg
			width='12'
			height='12'
			viewBox='0 0 12 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.99999 0.166748C2.77999 0.166748 0.166656 2.78008 0.166656 6.00008C0.166656 9.22008 2.77999 11.8334 5.99999 11.8334C9.21999 11.8334 11.8333 9.22008 11.8333 6.00008C11.8333 2.78008 9.21999 0.166748 5.99999 0.166748ZM5.99999 10.6667C3.42749 10.6667 1.33332 8.57258 1.33332 6.00008C1.33332 3.42758 3.42749 1.33341 5.99999 1.33341C8.57249 1.33341 10.6667 3.42758 10.6667 6.00008C10.6667 8.57258 8.57249 10.6667 5.99999 10.6667ZM4.83332 7.26591L8.67749 3.42175L9.49999 4.25008L4.83332 8.91675L2.49999 6.58341L3.32249 5.76091L4.83332 7.26591Z'
				fill='#3B7A71'
			/>
		</svg>
	);
};

const Back = () => {
	return (
		<svg
			className='Back'
			width='18'
			height='12'
			viewBox='0 0 18 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M18 5H3.83L7.41 1.41L6 0L0 6L6 12L7.41 10.59L3.83 7H18V5Z'
				fill='#461C3F'
			/>
		</svg>
	);
};

const LearnMoreIcon = () => {
	return (
		<svg
			className='LearnMoreIcon'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM7.25 4.25V5.75H8.75V4.25H7.25ZM7.25 7.25V11.75H8.75V7.25H7.25ZM2 8C2 11.3075 4.6925 14 8 14C11.3075 14 14 11.3075 14 8C14 4.6925 11.3075 2 8 2C4.6925 2 2 4.6925 2 8Z'
				fill='#3B7A71'
			/>
		</svg>
	);
};

const CloseIcon = () => {
	return (
		<svg
			className='CloseIcon'
			width='14'
			height='14'
			viewBox='0 0 14 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
				fill='#F9F9F9'
			/>
		</svg>
	);
};

const PackageModal = ({ checked, setOpen, addUserRole }) => {
	const toggleModal = () => {
		if (checked === '') {
			setOpen('checked');
		} else {
			setOpen('');
		}
	};

	return (
		<>
			<div className={checked === '' ? 'PackageModal' : 'PackageModal Active'}>
				<div className='ModalContent'>
					<div className='Packages'>
						<span onClick={toggleModal}>
							<CloseIcon />
						</span>
						<div className='TitleRow'>
							<div className='Column'>
								<div className='Title'>
									ENTREPRENEUR/ <br /> BUSINESS OWNER
								</div>
							</div>
							<div className='Column'>
								<div className='Title'>
									SUBJECT MATTER
									<br /> EXPERT{' '}
								</div>
							</div>
							<div className='Column'>
								<div className='Title'>
									BUSINESS COMMUNITY
									<br /> ORGANIZER
								</div>
							</div>
						</div>
						<div className='CostRow'>
							<div className='Cost'>$0/month</div>
							<div className='Cost'>$0/month</div>
							<div className='Cost'>
								$125/month <br /> $1200/year
							</div>
						</div>
						<div className='DescriptionRow'>
							<div className='Description'>
								<span className='Text'>
									Provides a community of fellow Entrepreneurs to support you
									along your journey and resources that can take your business
									to the next level.
								</span>
							</div>
							<div className='Description'>
								<span className='Text'>
									Enables you to provide those resources seamlessly, gain new
									clients, and increase your entrepreneurial impact.
								</span>
							</div>
							<div className='Description'>
								<span className='Text'>
									Increase the value of the business ecosystem that you have
									already created.
								</span>
							</div>
						</div>
						<div className='PackageRow'>
							<div className='Package'>Crib Customization</div>
							<div className='Package'>Host Office Hours</div>
							<div className='Package'>Events Calendar</div>
						</div>
						<div className='PackageRow'>
							<div className='Package'>Community Forum</div>
							<div className='Package'>Thought Leadership</div>
							<div className='Package'>Community Space</div>
						</div>
						<div className='PackageRow'>
							<div className='Package'>Member Connections</div>
							<div className='Package'>Sell Your Services</div>
							<div className='Package'>Resource Library</div>
						</div>
						<div className='ActionRow'>
							<div className='Action'>
								<Button className='Plan' onClick={() => addUserRole(0)}>
									Select Plan
								</Button>
							</div>
							<div className='Action'>
								<Button className='Plan' onClick={() => addUserRole(1)}>
									Select Plan
								</Button>
							</div>
							<div className='Action'>
								<Button className='Plan' onClick={() => addUserRole(2)}>
									Select Plan
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

/**
 * Recommended user role
 * - send selected user role to the server
 */
const ConfirmRole = ({
	role,
	setRoleSelected,
	checked,
	setOpen,
	addUserRole,
}) => {
	const index = role - 1;
	const roles = [
		'Entrepreneur',
		'Subject Matter Expert',
		'Business Community Organizer',
	];
	const roleDescription = [
		'People that are about  their business. ',
		'Entrepreneurs with services, tools, and content for the community.',
		'Leaders bringing your community of Entrepreneurs and SMEs to life.',
	];

	return (
		<div className='ConfirmRole'>
			<span onClick={() => setRoleSelected(0)}>
				{' '}
				<Back />
			</span>

			<div className='Content'>
				<span className='Title'>
					You've been recommended the{' '}
					<span className='RoleName'>{roles[index]}</span> user account!
				</span>
				<span className='Description'>{roleDescription[index]}</span>
				<Button className='Confirm' onClick={() => addUserRole(index)}>
					Confirm
				</Button>
				<span className='LearnMore' onClick={setOpen}>
					<LearnMoreIcon /> Learn more about Rialto’s account types.
				</span>
			</div>
			<PackageModal
				checked={checked}
				addUserRole={addUserRole}
				setOpen={setOpen}
			/>
		</div>
	);
};

/***
 * Select user role
 */
const SelectUserRole = ({ roleSelected, setRoleSelected, addUserRole }) => {
	const [role, setRole] = useState(0);
	const [checked, setOpen] = React.useState('');

	const selectRole = () => {
		if (role > 0) {
			setRoleSelected(role);
		}
	};

	return (
		<>
			{roleSelected === 0 ? (
				<div className='SelectUserRole'>
					<span className='Title'>Which option best describes you? *</span>
					<div className='Options'>
						<span className='Option' onClick={() => setRole(1)}>
							{role === 1 ? <TickDormant /> : <TickSelect />}
							<span className={role === 1 ? 'Active' : ''}>
								I'm a Black Founder/Business Owner
							</span>
						</span>
						<span className='Option' onClick={() => setRole(2)}>
							{role === 2 ? <TickDormant /> : <TickSelect />}
							<span className={role === 2 ? 'Active' : ''}>
								I am a business coach or consultant that supports Black
								Founders/Business Owners.
							</span>
						</span>
						<span className='Option' onClick={() => setRole(3)}>
							{role === 3 ? <TickDormant /> : <TickSelect />}
							<span className={role === 3 ? 'Active' : ''}>
								I manage a digital community that supports Black-owned
								businesses{' '}
							</span>
						</span>
					</div>
					<Button className='Submit' onClick={() => selectRole()}>
						Submit
					</Button>
				</div>
			) : (
				<ConfirmRole
					role={role}
					addUserRole={addUserRole}
					setRoleSelected={setRoleSelected}
					checked={checked}
					setOpen={setOpen}
				/>
			)}
		</>
	);
};

export default function ConfirmSignup() {
	const [roleSelected, setRoleSelected] = useState(0);
	const [screenWidth, setScreenWidth] = useState(0);
	const { token, firstname } = sessionInfo();
	const [sessionData, setSessionData] = useState(null);

	const navigate = useNavigate();

	const addUserRole = async (index) => {
		const roles = ['ENT', 'SME', 'BCO'];
		const role = roles[index];

		const body = { role, token };
		try {
			const res = await api.confirmRole(body);
			if (res.status === 'success') {
				if (res.data.token) {
					storeSession(res.data);
					setSessionData(res.data);
					navigate('/sign-up/success');
				}
			}
		} catch (error) {
			console.log('::: error :::');
			console.log(error);
		}
	};

	useEffect(() => {
		const width = Math.max(
			document.body.scrollWidth,
			document.documentElement.scrollWidth,
			document.body.offsetWidth,
			document.documentElement.offsetWidth,
			document.documentElement.clientWidth,
		);
		setScreenWidth(width);
	}, [screenWidth]);

	useEffect(() => {
		if (!isEmpty(sessionData)) {
			// identifyUserWithLou(sessionData, LOU);
		}
	}, [sessionData]);

	return (
		<div className='CreateAccountContainer'>
			{screenWidth > 601 ? <></> : <NavBar />}
			<div className='CreateAccount'>
				<div className='CreateAccountBanner'>
					<div className='BannerInfo'>
						<img
							src={appLogo.desktop}
							className='RialtoLogo'
							alt='Rialto Logo'
						/>
						<div>
							<span className='Title' style={{ textAlign: 'left' }}>
								Let's get you sorted {firstname ? `, ${firstname}!` : ''}
							</span>
							<span className='Content'>
								This lets us know what account type to create for you so you
								have the best experience on Rialto.
							</span>
						</div>
					</div>
					<CreateAccountRoot className='CreateAccountRoot' />
				</div>
				<div className='SelectRoleWrapper'>
					<SelectUserRole
						addUserRole={addUserRole}
						roleSelected={roleSelected}
						setRoleSelected={setRoleSelected}
					/>
				</div>
			</div>
		</div>
	);
}
