import React, { Component } from 'react';
import MemberUploadSuccess from '../assets/Icons/MemberUploadSuccess.svg';
import { ReactComponent as SignInRoot } from '../assets/Icons/CreateAccountRoot.svg';
import '../styles/pages/ForgotPasswordConfirmation.css';
import { appLogo } from 'config';

export default class ForgotPasswordConfirmation extends Component {
	render() {
		return (
			<div className='confirmation'>
				<div className='confirmation__banner'>
					<div className='confirmation__banner--container'>
						<div className='confirmation__banner--content'>
							<div className='confirmation__logo--container'>
								<img src={appLogo.desktop} className='RialtoLogo' alt='' />
							</div>
							<div className='confirmation__banner--info'>
								<span className='confirmation__banner--title'>
									Check your mail
								</span>
								<span className='confirmation__banner--sub'>
									A link has been sent to your email for you to recover your
									account.
								</span>
							</div>
						</div>
						<SignInRoot className='SignInRoot' />
					</div>
				</div>
				<div className='confirmation__flex'>
					<div className='confirmation__container'>
						<h2 className='confirmation__heading'>Link sent!</h2>
						<div className='confirmation__icon--container'>
							<img
								src={MemberUploadSuccess}
								className='confirmation__icon'
								alt=''
							/>
						</div>
						<h3 className='confirmation__subheading'>
							Password reset link sent! Please check your email.
						</h3>
					</div>
				</div>
			</div>
		);
	}
}
