import React, { useEffect, useState, useContext, useRef } from "react";
import NeighborhoodContext from "../contextStore/neighborhood-context";
import TwitterIcon from "../assets/svgs/TwitterIcon";
import FacebookIcon from "../assets/svgs/FacebookIcon";
import InstagramIcon from "../assets/svgs/InstagramIcon";
import { ReactComponent as LinkedInIcon } from "../assets/Icons/LinkedinIcon.svg";
import HomeIcon from "../assets/svgs/HomeIcon";
import BulletinIcon from "../assets/svgs/BulletinIcon";
import ForumIcon from "../assets/svgs/ForumIcon";
import LibraryIcon from "../assets/svgs/LibraryIcon";
import MembersIcon from "../assets/svgs/MembersIcon";
import neighborhood from "../api/neighborhood";
import inviteApi from "../api/signupFromInvite.js";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as Logo3 } from "../assets/Roots/RIALTO-ROOT-Pattern 2_Root Pattern 2 - white 3.svg";
import socialMediaHandleParser from "../utilities/textToSocialMediaLink.js";
import minwo_library_dropdown from "../assets/Images/minwo_library_dropdown.svg";
import defaultPicture from "../assets/Images/default-profile-picture.svg";
import getDataURLFromByteArray from "../utilities/getDataURLFromByteArray";
import isEmpty from "../utilities/isEmpty";
import store from "../utilities/storage";
import "../styles/components/NeighborhoodBanner.css";

const parseToWebLink = (link) => {
  return link.includes("https://") || link.includes("http://")
    ? link
    : `http://${link}`;
};

const routeToLink = (link) => {
  if (link) {
    window.location.href = parseToWebLink(link);
  }
};

export default function Banner({ links }) {
  const modalRef = useRef();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen((prev) => !prev);
  };
  useEffect(() => {
    const handler = (event) => {
      if (modalRef.current && !modalRef.current.value === event.target) {
        ctx.closeModal();
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [modalRef]);

  const ctx = useContext(NeighborhoodContext);
  let { neighborhood_id, invite_id } = useParams();
  const active_link = window.location.href;

  if (!neighborhood_id) {
    neighborhood_id = store.retrieve("neighborhood_id");
  }
  const [state, setState] = useState({
    Name: "",
    CompanyName: "",
    Link: "",
    Description: "",
    LogoURL: "#",
    FacebookURL: "#",
    InstagramURL: "#",
    TwitterURL: "#",
    LinkedInURL: "#",
  });

  useEffect(() => {
    (async () => {
      if (!isEmpty(neighborhood_id) || !isEmpty(invite_id)) {
        let proof;

        if (invite_id) {
          proof = await inviteApi.getInviteNeighborhood(
            neighborhood_id,
            invite_id
          );
        } else {
          proof = await neighborhood.getNeighborhood(neighborhood_id);
        }

        if (proof && proof.data) {
          store.write(
            "neighborhood_name",
            proof.data.neighborhood.neighborhood_name
          );
          const n_picture =
            proof.data?.neighborhood_picture === null
              ? null
              : proof.data?.neighborhood_picture;
          let pic;
          if (n_picture) {
            if (Array.isArray(n_picture?.buffer?.data)) {
              pic = await getDataURLFromByteArray(
                n_picture.buffer.data,
                n_picture.mimetype
              );
              store.write("neighborhood_picture", pic);
            } else {
              // pic = store.retrieve("neighborhood_picture") || '';
            }
          }
          const state_info = {
            Name: proof.data.neighborhood.neighborhood_name,
            CompanyName: proof.data.neighborhood.company_name,
            Description: proof.data.neighborhood.neighborhood_description,
            Link: proof.data.neighborhood.company_website,
            LogoURL: pic,
            FacebookURL: socialMediaHandleParser(
              "facebook",
              proof.data.neighborhood.facebook
            ),
            InstagramURL: socialMediaHandleParser(
              "instagram",
              proof.data.neighborhood.instagram
            ),
            TwitterURL: socialMediaHandleParser(
              "twitter",
              proof.data.neighborhood.twitter
            ),
            LinkedInURL: socialMediaHandleParser(
              "linkedin",
              proof.data.neighborhood.linkedin
            ),
          };
          setState(state_info);
        }
      }
    })();
  }, [neighborhood_id]);
  const [libraryVal, setLibraryVal] = useState("Library");
  const [spanVal, setSpanVal] = useState("Library");
  const rotateImg = !ctx.isNeighborhoodOpen
    ? { transform: "rotate(0)" }
    : { transform: "rotate(180deg)" };
  useEffect(() => {
    setSpanVal(libraryVal);
  }, [libraryVal]);

  const clickOptionHandler = (event, name) => {
    event.preventDefault();
    setOpen(false);
    setLibraryVal(name);
  };

  const libraryOptions = [
    // {
    //   name: "Office Hours",
    //   link: `/neighborhood/${neighborhood_id}/library/office-hours`,
    // },
    {
      name: "Resources",
      link: `/neighborhood/${neighborhood_id}/library/resources`,
    },
    { name: "Events", link: `/neighborhood/${neighborhood_id}/library/events` },
    {
      name: "Services",
      link: `/neighborhood/${neighborhood_id}/library/services`,
    },
  ];
  const librarayOptionsMapped = libraryOptions.map((item) => {
    return (
      <li
        key={item.name}
        onClick={(event) => clickOptionHandler(event, item.name)}
      >
        <Link to={item.link}>{item.name}</Link>
      </li>
    );
  });

  const picture = state.LogoURL;
  return (
    <div className="NeighborhoodBanner">
      <div className="Header">
        <div className="Info">
          <a href={`http://${state.Link}`} target="_blank" rel="noreferrer">
            <img
              className="Logo"
              alt="Logo"
              src={
                picture?.includes(null) || !picture || picture === "#"
                  ? defaultPicture
                  : picture
              }
            />
          </a>
          <div className="MobileWrapper">
            <span
              className="Link pointer"
              onClick={() => routeToLink(state.Link)}
            >
              {state.CompanyName}
            </span>
            <span className="SocialIcons">
              <a
                href={state.FacebookURL}
                target="_blank"
                rel="noreferrer"
                className="LinkPointer"
              >
                <FacebookIcon className="Icon" />
              </a>
              <a
                href={state.InstagramURL}
                target="_blank"
                rel="noreferrer"
                className="LinkPointer"
              >
                <InstagramIcon className="Icon" />
              </a>
              <a
                href={state.TwitterURL}
                target="_blank"
                rel="noreferrer"
                className="LinkPointer"
              >
                <TwitterIcon className="Icon LinkPointer" />
              </a>
              <a
                href={state.LinkedInURL}
                target="_blank"
                rel="noreferrer"
                className="LinkPointer"
              >
                <LinkedInIcon className="Icon LinkPointer" />
              </a>
            </span>
          </div>
        </div>

        <div className="Content">
          <span className="Title">{state.Name}</span>
          <span className="Description">{state.Description}</span>
        </div>
        <Logo3 className="pattern" />
      </div>
      <div className="NavBar">
        <a href={`/neighborhood/${neighborhood_id}`}>
          <span
            className={`Link ${
              active_link ===
              `${window.location.origin}/neighborhood/${neighborhood_id}`
                ? "Active"
                : ""
            }`}
          >
            <HomeIcon className="Icon" />
            <span>Home</span>
          </span>
        </a>
        <a href={`/neighborhood/${neighborhood_id}/bulletin`}>
          <span
            className={`Link ${active_link === "Bulletin" ? "Active" : ""}`}
          >
            <BulletinIcon className="Icon" /> <span>Bulletin Board</span>
          </span>
        </a>
        <a href={`/neighborhood/${neighborhood_id}/forum`}>
          <span
            className={`Link ${
              active_link ===
              `${window.location.origin}/neighborhood/${neighborhood_id}/forum`
                ? "Active"
                : ""
            }`}
          >
            <ForumIcon className="Icon" />
            <span>Forum</span>
          </span>
        </a>

        <div
          className={`Link ${
            active_link.includes("library") ? "Active" : ""
          } library`}
        >
          <LibraryIcon className="Icon" />
          <div className="libSpan" onClick={toggle}>
            {spanVal}{" "}
            <img
              src={minwo_library_dropdown}
              alt="dropdown"
              style={rotateImg}
            />{" "}
          </div>
          {open ? (
            <ul
              className="dropDown"
              ref={modalRef}
              // tabIndex={1}
              // onBlur={ctx.closeNav}
            >
              {librarayOptionsMapped}
            </ul>
          ) : null}
        </div>
        <a href={`/neighborhood/${neighborhood_id}/members`}>
          <span
            className={`Link ${
              active_link ===
              `${window.location.origin}/neighborhood/${neighborhood_id}/members`
                ? "Active"
                : ""
            } `}
          >
            <MembersIcon className="Icon" />
            <span>Members</span>
          </span>
        </a>
      </div>
      {/* <ChatBot /> */}
    </div>
  );
}
