import React from "react";
import { BrowserRouter } from "react-router-dom";
import Containers from "./containers";
import "./App.css";

const App = () => {
  // TODO: Use semantic versioning standard for rialto app versioning
  // see: https://semver.org/
  console.info("APP VERSION: 1.1.2");
  return (
    <BrowserRouter>
      <Containers />
    </BrowserRouter>
  );
};

export default App;
