import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import NeighborhoodBanner from "../components/NeighborhoodBanner";
import AppLoader from "../components/Loader/loader";
import { AppLayout } from "./AppLayout";

const NeighborhoodLayout = () => {
  return (
    <Suspense fallback={<AppLoader />}>
      <AppLayout>
        <NeighborhoodBanner />
        <div>
          <Outlet />
        </div>
      </AppLayout>
    </Suspense>
  );
};

export default NeighborhoodLayout;
